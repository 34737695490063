import React from "react";
import { graphql } from "gatsby";
import { compose } from "recompose";
// @material-ui
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  createStyles,
  withStyles,
  WithStyles,
  hexToRgb
} from "@material-ui/core/styles";

import SyncIcon from "@material-ui/icons/CompareArrows";
import ProtectIcon from "@material-ui/icons/AddBox";
import PieChartIcon from "@material-ui/icons/PieChart";
import WebIcon from "@material-ui/icons/Web";

import MainLayout from "../components/layout/MainLayout";
import { Container } from "../components/general";
import { SpecificTheme } from "../theme/default";
import { LandingPageDashboardPageQuery } from "./__generated__/LandingPageDashboardPageQuery";
import { PageProps } from "../typings";
import Footer from "../components/general/Footer";
import colors from "../theme/defaultTheme/colors";
import BackgroundLanding from "../components/landing-page/BackgroundLanding";
import SEO from "../components/SEO";
import InfoArea from "../components/landing-page/InfoArea/info-area";
import GridItem from "../components/Grid/GridItem";
import GridContainer from "../components/Grid/GridContainer";
import Grid from "@material-ui/core/Grid";

const styles = (theme: SpecificTheme) =>
  createStyles({
    root: {
      color: colors.whiteText,
      "& a": {
        color: colors.whiteText,
        textDecoration: "none"
      },
      "& h2": {
        fontSize: "2.5rem"
      }
    },

    list: {
      // marginTop: "1.55rem"
    },

    transparentSection: {
      color: "white"
    },

    // Material UI Pro styles
    mainRaised: {
      "@media (max-width: 576px)": {
        marginTop: "-30px"
      },
      "@media (max-width: 830px)": {
        marginLeft: "10px",
        marginRight: "10px"
      },
      backgroundColor: "#fff",
      borderRadius: "6px",
      boxShadow:
        "0 16px 24px 2px rgba(" +
        hexToRgb(theme.palette.common.black) +
        ", 0.14), 0 6px 30px 5px rgba(" +
        hexToRgb(theme.palette.common.black) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(theme.palette.common.black) +
        ", 0.2)",
      margin: "60px 30px 15px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px"
      },
      [theme.breakpoints.up("md")]: {
        // minHeight: "37vh"
      }
    },

    textSection: {
      marginTop: "5vh",
      [theme.breakpoints.up("md")]: {
        marginTop: "9vh"
      }
    }
  });

type Props = WithStyles<typeof styles> &
  PageProps<LandingPageDashboardPageQuery>;

const statistikTool: React.FC<Props> = ({ classes, data, location }) => {
  return (
    <MainLayout location={location} title={"Dashboard"}>
      <Container variant="standard" color="primary" className={classes.root}>
        <BackgroundLanding>
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ minHeight: "82vh" }}
          >
            <Grid item xs={12}>
              <Container variant="standard" padding color="transparent">
                <div className={classes.textSection}>
                  <Typography
                    variant="caption"
                    style={{ fontSize: "1.5rem", marginBottom: "1rem" }}
                  >
                    clever stats.
                  </Typography>

                  <Typography variant="h1" style={{ marginBottom: "1.5rem" }}>
                    Dynamische Auswertung Ihrer Daten
                  </Typography>

                  <Typography variant="body1" style={{ marginBottom: "1rem" }}>
                    Dashboards nach individuellen Bedüftnissen - entwickelt für
                    Sie!
                    <ul className={classes.list}>
                      <li>Umfassende und übersichtliche Auswertungen</li>
                      <li>Benutzerfreundliche Bedienung</li>
                      <li>
                        Adaptierbar auf Ihre unternehmerischen Bedürfnisse
                      </li>
                      <li>
                        Webbasiertes Statistik-Tool ersetzt Excel-Tabellen
                      </li>
                    </ul>
                  </Typography>

                  <Typography variant="body1" style={{ marginBottom: "2rem" }}>
                    Überzeugen Sie sich selbst im Demo-Dashboard von den
                    Vorteilen.
                    <br />
                    Dafür ist keine Registrierung notwendig.
                  </Typography>

                  <Button
                    variant="contained"
                    color="primary"
                    href="https://demo-stats.twteam.ch"
                  >
                    DEMO-APP TESTEN
                  </Button>
                </div>
              </Container>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.mainRaised}>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={3}>
                    <InfoArea
                      title="Individualität"
                      description="Mehrwert generieren: Entwicklung nach Ihren individuellen Bedürfnissen."
                      icon={ProtectIcon}
                      iconColor="primary"
                      textColor="primary"
                      center
                      vertical
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <InfoArea
                      title="Klarheit"
                      description="Schnell zum Ziel: Einfacher Zugang zu entscheidungsrelevanten Informationen."
                      icon={WebIcon}
                      iconColor="primary"
                      textColor="primary"
                      center
                      vertical
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <InfoArea
                      title="Browser-Sync"
                      description="Ohne Zusatzaufwand: Erfasste Daten werden direkt im Browser ausgewertet und abgefragt."
                      icon={SyncIcon}
                      iconColor="primary"
                      textColor="primary"
                      center
                      vertical
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <InfoArea
                      title="Flexibilität"
                      description="Vielfältige Auswertung: Analysieren Sie Ihre Daten aus unterschiedlichen Perspektiven."
                      icon={PieChartIcon}
                      iconColor="primary"
                      textColor="primary"
                      center
                      vertical
                    />
                  </GridItem>
                </GridContainer>
              </div>
            </Grid>
          </Grid>
        </BackgroundLanding>
      </Container>

      <Footer />
      {/* SEO, head params here */}
      <SEO
        general={{
          contentType: "website",
          description: data.contentfulPages.seoDescription,
          language: "de",
          path: location.pathname,
          title: data.contentfulPages.seoTitle
        }}
      />
    </MainLayout>
  );
};

export default compose<Props, {}>(withStyles(styles))(statistikTool);

/*
 * Sizes is used for fluid containers
 * Resolution is used for fixed width
 */
export const kontaktQuery = graphql`
  query LandingPageDashboardPageQuery {
    contentfulPages(page: { eq: "LandingPageDashboard" }) {
      seoTitle
      seoDescription
    }
    ...logoFragment
  }
`;
